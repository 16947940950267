import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import NotFound from './NotFound';
import NoNavLayout from './NoNavLayout';

const CableRedirect = () => {
  const { cable_id } = useParams();
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUrl = async () => {
      try {
        const response = await axios.get(`/api/cable-urls/${cable_id}`);
        window.location.href = response.data.url;
      } catch (error) {
        setError('Cable ID not found');
      }
    };

    fetchUrl();
  }, [cable_id]);

  if (error) {
    return (
      <NoNavLayout>
        <NotFound />
      </NoNavLayout>
    );
  }

  return <div>Loading...</div>;
};

export default CableRedirect;
