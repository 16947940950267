import React, { useState, useEffect, useContext, useCallback } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { AuthContext } from '../context/AuthContext';
import './AdminDashboard.css';

// Set the root element for the modal
Modal.setAppElement('#root');

const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [cables, setCables] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userModalIsOpen, setUserModalIsOpen] = useState(false);
  const [cableModalIsOpen, setCableModalIsOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [newUser, setNewUser] = useState({ name: '', email: '', password: '', is_admin: false });
  const [newCable, setNewCable] = useState({ cable_id: '', url: '' });
  const [currentCableId, setCurrentCableId] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const { authToken } = useContext(AuthContext);

  const fetchUsers = useCallback(async () => {
    try {
      const response = await axios.get('/api/users', {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  }, [authToken]);

  const fetchCables = useCallback(async () => {
    try {
      const response = await axios.get('/api/cable-urls', {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setCables(response.data);
    } catch (error) {
      console.error('Error fetching cables:', error);
    }
  }, [authToken]);

  const handleDeleteUser = async (userId) => {
    try {
      await axios.delete(`/api/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      fetchUsers();
      setSuccessMessage('User deleted successfully');
      setTimeout(() => setSuccessMessage(''), 3000); // Clear success message after 3 seconds
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const handleUserInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewUser({
      ...newUser,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleAddUser = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/api/users', newUser, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      fetchUsers();
      setNewUser({ name: '', email: '', password: '', is_admin: false });
      setUserModalIsOpen(false); // Close modal after adding user
      setSuccessMessage('User added successfully');
      setTimeout(() => setSuccessMessage(''), 3000); // Clear success message after 3 seconds
    } catch (error) {
      console.error('Error adding user:', error);
    }
  };

  const handleCableInputChange = (e) => {
    const { name, value } = e.target;
    setNewCable({
      ...newCable,
      [name]: value,
    });
  };

  const handleAddCable = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/api/cable-urls', newCable, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      fetchCables();
      setNewCable({ cable_id: '', url: '' });
      setCableModalIsOpen(false); // Close modal after adding cable URL
      setErrorMessage(''); // Clear error message
      setSuccessMessage('Cable URL added successfully');
      setTimeout(() => setSuccessMessage(''), 3000); // Clear success message after 3 seconds
    } catch (error) {
      if (error.response && error.response.status === 422) {
        setErrorMessage('This Cable ID or URL already exists. Please update it instead.');
      } else {
        console.error('Error adding cable URL:', error);
      }
    }
  };

  const handleEditCable = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`/api/cable-urls/${currentCableId}`, newCable, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      fetchCables();
      setNewCable({ cable_id: '', url: '' });
      setCableModalIsOpen(false); // Close modal after updating cable URL
      setErrorMessage(''); // Clear error message
      setIsUpdating(false);
      setCurrentCableId(null);
      setSuccessMessage('Cable URL updated successfully');
      setTimeout(() => setSuccessMessage(''), 3000); // Clear success message after 3 seconds
    } catch (error) {
      if (error.response && error.response.status === 422) {
        setErrorMessage('This Cable ID or URL already exists. Please update it instead.');
      } else {
        console.error('Error updating cable URL:', error);
      }
    }
  };

  const handleEditClick = (cable) => {
    setNewCable({ cable_id: cable.cable_id, url: cable.url });
    setCurrentCableId(cable.id);
    setIsUpdating(true);
    setCableModalIsOpen(true);
  };

  useEffect(() => {
    const loadData = async () => {
      await fetchUsers();
      await fetchCables();
      setLoading(false);
    };

    loadData();
  }, [fetchUsers, fetchCables]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>Admin Dashboard</h1>
      <h2>Users</h2>
      <button onClick={() => setUserModalIsOpen(true)}>Add User</button>
      <ul>
        {users.map((user) => (
          <li key={user.id}>
            {user.name} ({user.email}) - {user.is_admin ? 'Admin' : 'User'}
            <button onClick={() => handleDeleteUser(user.id)}>Delete</button>
          </li>
        ))}
      </ul>
      <Modal
        isOpen={userModalIsOpen}
        onRequestClose={() => setUserModalIsOpen(false)}
        contentLabel="Add User"
      >
        <h2>Add User</h2>
        <form onSubmit={handleAddUser}>
          <input
            type="text"
            name="name"
            value={newUser.name}
            onChange={handleUserInputChange}
            placeholder="Name"
            required
          />
          <input
            type="email"
            name="email"
            value={newUser.email}
            onChange={handleUserInputChange}
            placeholder="Email"
            required
          />
          <input
            type="password"
            name="password"
            value={newUser.password}
            onChange={handleUserInputChange}
            placeholder="Password"
            required
          />
          <label>
            Admin
            <input
              type="checkbox"
              name="is_admin"
              checked={newUser.is_admin}
              onChange={handleUserInputChange}
            />
          </label>
          <button type="submit">Add User</button>
          <button onClick={() => setUserModalIsOpen(false)}>Cancel</button>
        </form>
      </Modal>
      <h2>Cables</h2>
      <button onClick={() => {
        setIsUpdating(false);
        setNewCable({ cable_id: '', url: '' });
        setCableModalIsOpen(true);
      }}>
        Add Cable URL
      </button>
      <ul>
        {cables.map((cable) => (
          <li key={cable.id}>
            {cable.cable_id} - {cable.url}
            <button onClick={() => handleEditClick(cable)}>Edit</button>
          </li>
        ))}
      </ul>
      <Modal
        isOpen={cableModalIsOpen}
        onRequestClose={() => setCableModalIsOpen(false)}
        contentLabel={isUpdating ? "Update Cable URL" : "Add Cable URL"}
      >
        <h2>{isUpdating ? "Update Cable URL" : "Add Cable URL"}</h2>
        <form onSubmit={isUpdating ? handleEditCable : handleAddCable}>
          <input
            type="text"
            name="cable_id"
            value={newCable.cable_id}
            onChange={handleCableInputChange}
            placeholder="Cable ID"
            required
            disabled={isUpdating}
          />
          <input
            type="url"
            name="url"
            value={newCable.url}
            onChange={handleCableInputChange}
            placeholder="URL"
            required
          />
          <button type="submit">{isUpdating ? "Update Cable URL" : "Add Cable URL"}</button>
          <button onClick={() => setCableModalIsOpen(false)}>Cancel</button>
        </form>
        {errorMessage && <p className="error">{errorMessage}</p>}
        {successMessage && <p className="success">{successMessage}</p>}
      </Modal>
    </div>
  );
};

export default AdminDashboard;
